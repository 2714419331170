import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { LoadingIcon } from '@junglescout/edna'
import { injectReducers } from 'reducers'
import store from '../../store'
import { ErrorBoundary } from './ErrorBoundary'
import { logExceptionNoConsole } from '../../config/sentry'

const IconWrapper = styled.div`
  position: fixed;
  top: calc(50vh - 32.5px);
  left: calc(50vw - 25px);
`

const failedHash = new Map() // Stores failed for different pathname pages

export class DynamicImport extends Component {
  static updateStore(reducers) {
    if (!reducers.every(reducer => reducer.default?.key)) {
      throw new Error(
        'No "key" given for dynamically imported reducer. Please add a key to the reducer export'
      )
    }

    const dynamicReducers = reducers.map(reducer => {
      const {
        default: { key, reducer: asyncReducer }
      } = reducer

      return {
        key,
        reducer: asyncReducer
      }
    })

    // add reducers to the store (this method does a duplicate check)
    injectReducers(store, dynamicReducers)
  }

  mounted = false

  constructor(props) {
    super(props)

    if (!props.load) {
      throw new Error('load prop must be supplied')
    }

    this.state = {
      component: null
    }
  }

  async componentDidMount() {
    const pathname = this.getPathname()
    if (pathname && failedHash.has(pathname)) return

    this.mounted = true

    try {
      // load the component
      const { component, dynamicReducers } = await this.loadScripts()

      if (dynamicReducers?.length) {
        DynamicImport.updateStore(dynamicReducers)
      }

      if (!component) {
        throw new Error('Failed to load component')
      }

      if (this.mounted) {
        // save it for rendering
        this.setState({
          component: component.default || component
        })
      }
    } catch (ex) {
      if (this.mounted) {
        failedHash.set(pathname, ex)
        this.logError(ex)
      }
    }
  }

  async componentDidUpdate(prevProps) {
    // if we swap between URLS which are both dynamically loaded
    // we need to do another load. So unless the load prop is the same
    // just call componentDidMount
    const { load } = this.props
    if (prevProps.load.toString() === load.toString()) {
      return
    }

    this.componentDidMount()
  }

  componentWillUnmount() {
    this.mounted = false
  }

  getPathname = () => {
    const { location } = this.props
    return location?.pathname || ''
  }

  isValidComponent = component => {
    // If error occurs outside the component, Module without a type attribute would be returned
    // React cannot render a Module without a type attribute
    // So it is necessary to verify that the module is a valid component
    return (
      component &&
      (typeof component === 'function' || component.default || component.type)
    )
  }

  logError = error => {
    const pathname = this.getPathname()
    logExceptionNoConsole(error, { extra: { pathname } })
  }

  async loadScripts() {
    // we need the bind here otherwise you get "PromiseResolve called on non-object" errors
    const { load = Promise.resolve.bind(Promise), reducers } = this.props
    const reducersPromises = reducers.map(reducer => reducer())

    // resolve component and dynamic reducers and return them
    return Promise.all([load(), ...reducersPromises]).then(
      ([component, ...dynamicReducers]) => {
        return {
          component,
          dynamicReducers
        }
      }
    )
  }

  renderLoadingState() {
    const pathname = this.getPathname()
    if (pathname && failedHash.has(pathname)) return null
    return (
      <IconWrapper>
        <LoadingIcon size="50px" />
      </IconWrapper>
    )
  }

  renderFailedState(failed) {
    const { component } = this.state
    const { location } = this.props
    // note we need the {...props.location} here to make the comparison work in componentDidUpdate
    return (
      <ErrorBoundary
        dynamicImport={component}
        location={{ ...location }}
        error={failed}
      />
    )
  }

  render() {
    const props = { ...this.props }
    const { component: DynamicComponent } = this.state
    const pathname = this.getPathname()
    delete props.load // don't want to pass this around
    delete props.reducers // don't want to pass this around

    if (pathname && failedHash.has(pathname)) {
      return this.renderFailedState(failedHash.get(pathname))
    }

    // note we need the {...props.location} here to make the comparison work in componentDidUpdate
    // we also need the {...props} in <this.state.component line because props could be anything else
    /* eslint-disable react/jsx-props-no-spreading */
    return this.isValidComponent(DynamicComponent) ? (
      <ErrorBoundary
        dynamicImport={DynamicComponent}
        location={{ ...props.location }}>
        <DynamicComponent {...props} />
      </ErrorBoundary>
    ) : (
      this.renderLoadingState()
    )
    /* eslint-enable react/jsx-props-no-spreading */
  }
}

DynamicImport.defaultProps = {
  reducers: []
}

DynamicImport.propTypes = {
  location: PropTypes.objectOf(PropTypes.any).isRequired,
  load: PropTypes.func.isRequired,
  reducers: PropTypes.arrayOf(PropTypes.func)
}
